import axios from "axios"

class Search {
  // 1. describe and create/initiate our object
  constructor() {
    this.addSearchHTML()
    this.resultsDiv = document.querySelector("#search-overlay__results")
    this.openButton = document.querySelectorAll(".js-search-trigger")
    this.closeButton = document.querySelector(".search-overlay__close")
    this.searchOverlay = document.querySelector(".search-overlay")
    this.searchField = document.querySelector("#search-term")
    this.isOverlayOpen = false
    this.isSpinnerVisible = false
    this.previousValue
    this.typingTimer
    this.events()
  }

  // 2. events
  events() {
    this.openButton.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault()
        this.openOverlay()
        console.log("Open search tab")
      })
    })

    this.closeButton.addEventListener("click", () => this.closeOverlay())
    // document.addEventListener("keydown", e => this.keyPressDispatcher(e))
    this.searchField.addEventListener("keyup", () => this.typingLogic())
  }

  // 3. methods (function, action...)
  typingLogic() {
    if (this.searchField.value != this.previousValue) {
      clearTimeout(this.typingTimer)

      if (this.searchField.value) {
        if (!this.isSpinnerVisible) {
          this.resultsDiv.innerHTML = '<div class="spinner-loader"></div>'
          this.isSpinnerVisible = true
        }
        this.typingTimer = setTimeout(this.getResults.bind(this), 750)
      } else {
        this.resultsDiv.innerHTML = ""
        this.isSpinnerVisible = false
      }
    }

    this.previousValue = this.searchField.value
  }

  async getResults() {
    try {
        const response = await axios.get(appData.root_url + "/wp-json/biovancia/v1/search?term=" + this.searchField.value)
        const results = response.data
        const resultsDiv = document.querySelector("#search-overlay__results")
        const ignore = ['Checkout', 'Copy Paste', 'account-search', 'My account', 'Cart', 'Sample Page'].map(item => item.toLowerCase().trim());
        console.log(results)
        // Check if resultsDiv exists
        if (!resultsDiv) {
            console.error("Results container not found")
            return
        }
        
        // Filter out ignored titles (trim and convert to lowercase)
        const filteredGeneralInfo = results.generalInfo.filter(item => 
            !ignore.includes(item.title.toLowerCase().trim())
        );
        const filteredProducts = results.products.filter(item => 
            !ignore.includes(item.title.toLowerCase().trim())
        );
        // Debug filtered results
        console.log("Filtered General Info:", filteredGeneralInfo);
        console.log("Filtered Products:", filteredProducts);

        // Construct the HTML string
        const generalInfoHTML = `
           <div class="row">
               <div class="elementor-posts-container">
                   ${filteredGeneralInfo.length || filteredProducts.length ? ' ' : "<p>Aucun résultats correspondants, veuillez réessayer</p>"}
                   
                   ${filteredGeneralInfo.map(item => `
                       <div>
                           <a href="${item.permalink}">
                           ${item.image ? `<img src="${item.image}" alt="${item.title}" />` : ''}
                           <div class="one-third">
                               <h3>${item.title}</h3>
                               <a href="${item.permalink}">- VOIR LA PAGE -</a>
                           </div>
                           </a> 
                       </div>`).join("")
                   }
   
                   ${filteredProducts.map(item => `
                       <div>
                           <a href="${item.permalink}">
                           <div class="one-third">
                               <h3>${item.title}</h3>
                           </div>
                           </a> 
                       </div>`).join("")
                   }
               </div>
        </div>`
   
           // Update the innerHTML
           resultsDiv.innerHTML = generalInfoHTML
           this.isSpinnerVisible = false
       } catch (e) {
            console.log(e)
    }
  }

  // keyPressDispatcher(e) {
    // if (e.keyCode == 83 && !this.isOverlayOpen && document.activeElement.tagName != "INPUT" && document.activeElement.tagName != "TEXTAREA") {
    //   this.openOverlay()
    // }

    // if (e.keyCode == 27 && this.isOverlayOpen) {
    //   this.closeOverlay()
    // }
  // }

  openOverlay() {
    this.searchOverlay.classList.add("search-overlay--active")
    document.body.classList.add("body-no-scroll")
    this.searchField.value = ""
    setTimeout(() => this.searchField.focus(), 301)
    this.isOverlayOpen = true
    return false
  }

  closeOverlay() {
    this.searchOverlay.classList.remove("search-overlay--active")
    document.body.classList.remove("body-no-scroll")
    console.log("our close method just ran!")
    this.isOverlayOpen = false
  }

  addSearchHTML() {
    document.body.insertAdjacentHTML(
      "beforeend",
      `
      <div class="search-overlay">
        <div class="search-overlay__top">
          <div class="container">
            <i class="fa fa-search search-overlay__icon" aria-hidden="true"></i>
            <input type="text" class="search-term" placeholder="Tapez et appuyez sur &quot;Entrée&quot;" id="search-term">
            <!-- <i class="fa fa-window-close search-overlay__close" aria-hidden="true"></i> -->
            <button class="search-overlay__close" aria-label="Close search overlay">×</button>
          </div>
        </div>
        
        <div class="container">
          <div id="search-overlay__results"></div>
        </div>

      </div>
    `
    )
  }
}

export default Search
