import 'swiper/swiper-bundle.min.css';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import '../css/load.scss';

import Swiper from 'swiper/swiper-bundle.min.mjs';
import { Fancybox } from '@fancyapps/ui';
import Search from './modules/Search';

document.addEventListener('DOMContentLoaded', () => {
	new Search(); // Initialize the Search class
});

(function ($) {
	const $win = $(window);
	const $doc = $(document);
	let redirectInProgress = false;

	initHeaderMenuToggler();
	enableOffersPicker();
	initOffersCloser();
	initSlider('.slider-testimonials', 5000);
	initSlider('.slider-products');
	initSlider('.slider-testimonials-alt', 5000);
	initSlider('.slider-articles', 6000);
	initSliderMulti('.slider-products-alt');
	initFancyBoxAlbum('album-product');
	initFaq();
	enableOffersEdit();
	initRemoveProductConfirmation();
	initStickyPayment();
	// closePayment();
	// makeFormPopup();
	initCartPrefill();
	initSnackbar();
	initCustomCouponsForm();
	wcmFieldCorrection();

	function closePayment() {
		const $overlay = $('.overlay__close');
		if (!$overlay.length) {
			return;
		}

		$overlay.on('click', function (e) {
			e.preventDefault();
		});
	}

	function initRemoveProductConfirmation() {
		const $removeBtn = $('.remove');
		const $overlay = $('.remove-overlay');
		if (!$removeBtn.length || !$overlay.length) {
			return;
		}

		$removeBtn.on('click', function (e) {
			e.preventDefault();

			const removeUrl = $(this).data('removeurl');
			$overlay.addClass('active');
			$('.main').find('.remove-real').attr('href', removeUrl);
		});

		$('.edit-alt').on('click', function (e) {
			e.preventDefault();

			$overlay.removeClass('active');
		});
	}

	function initStickyPayment() {
		const $payment = $('#payment');
		if (!$payment.length) {
			return;
		}

		$win.on('scroll', function () {
			var parent = $('.checkout.woocommerce-checkout');
			var child = $('#payment');
			var services = $('.shell--services');

			var parentTop = parent.offset().top;
			var parentBottomAlt = parentTop + parent.outerHeight(true);
			var parentBottom = $('.section-payment-logos').offset().top;
			var windowTop = $(window).scrollTop();
			var windowBottom = windowTop + $(window).height();
			var servicesBottom = services.offset().top + services.outerHeight(true);

			if (windowBottom >= parentBottomAlt) {
				child.addClass('fixed');
				child.css({ top: 'auto' });

				if (windowBottom <= parentBottom) {
					child.css({ bottom: '10px' });
				} else {
					child.css({ bottom: windowBottom - servicesBottom + 'px' });
				}
			} else {
				child.removeClass('fixed');
			}
		});
	}

	function initFaq() {
		const $faq = $('.faq');

		if (!$faq.length) {
			return;
		}

		const $faqHead = $faq.find('.faq__head');

		$faqHead.on('click', function () {
			$(this).parents('.faq').toggleClass('active').find('.faq__body').toggle('fast');
		});
	}

	function addOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').addClass('no-scroll');
		$overlay.addClass('active');
	}

	function removeOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').removeClass('no-scroll');
		$overlay.removeClass('active');
	}

	function initHeaderMenuToggler() {
		$('.header .header__menu-toggle').on('click', function () {
			$(this).siblings('.header__content').toggleClass('menu-open');
		});

		$('.header .header__menu-close').on('click', function () {
			$(this).parents('.header__content').removeClass('menu-open');
		});

		if (window.innerWidth <= 768) {
			$('.menu-item-has-children').on('click', function (e) {
				e.preventDefault();

				$(this).toggleClass('show-dd');
			});
			console.log('You are using a mobile device');
		}
	}

	function enableOffersPicker() {
		if (!$('.btn-add-cart').length || !$('#form-cart').length) {
			return;
		}

		$('.btn-add-cart').on('click', function (e) {
			e.preventDefault();

			addOverlay('.app-loader-overlay');
			$('.cart-quantity-popup').addClass('active');

			$('.cart__quantity').on('click', function () {
				let offer = $(this).data('offer');
				$('#cart-product-id').val(offer);
				let quantity = 1;
				let addToCart = offer;
				addOverlay('.app-loader');

				$.ajax({
					url: appData.ajaxUrl,
					data: {
						action: 'app_add_product_to_cart_auto',
						product_id: addToCart,
						quantity: quantity
					},
					success: function (response) {
						if (response.success === false) {
							window.location.href = response.data.url;
						} else {
							window.location.href = response.data.url;
						}
					},
					error: function () {
						console.log('Product Error');
						removeOverlay('.app-loader');
					}
				});
			});
		});

		$('#form-cart').on('submit', function (e) {
			e.preventDefault();
		});
	}

	function enableOffersEdit() {
		$('.edit').on('click', function (e) {
			e.preventDefault();

			addOverlay('.app-loader');
			addOverlay('.app-loader-overlay');
			const productId = $(this).data('product_id');
			const parentId = $(this).data('parent_id');

			$.ajax({
				url:
					appData.ajaxUrl +
					'?action=app_edit_product&pid=' +
					productId +
					'&parent_id=' +
					parentId
			}).then((response) => {
				$('body').append(response.data);

				removeOverlay('.app-loader');
			});
		});

		$('body').on('click', '.cart__quantity-item', function (e) {
			e.preventDefault();

			let offer = $(this).data('offer');
			let initial = $(this).data('initial');

			addOverlay('.app-loader');

			$.ajax({
				url:
					appData.ajaxUrl +
					'?action=app_edit_update_product&oid=' +
					offer +
					'&initial=' +
					initial
			}).then((response) => {
				window.location.href = response.data.url;
			});
		});
	}

	function initOffersCloser() {
		$('body').on('click', '.cart__close', function () {
			$('.cart-quantity-popup').removeClass('active');
			removeOverlay('.app-loader-overlay');
		});
	}

	function initSlider(elementSelector, autoplayDelay) {
		const swiperConfig = {
			loop: true,
			slidesPerView: 1,
			spaceBetween: 16,
			pagination: {
				el: '.swiper-pagination'
			},
			navigation: {
				nextEl: `${elementSelector} + .swiper-buttons .swiper-button-next`,
				prevEl: `${elementSelector} + .swiper-buttons .swiper-button-prev`
			}
		};

		if (typeof autoplayDelay === 'number') {
			swiperConfig.autoplay = {
				delay: autoplayDelay
			};
		}

		const swiper = new Swiper('.swiper' + elementSelector, swiperConfig);
	}

	function initSliderMulti(elementSelector) {
		const swiper = new Swiper('.swiper' + elementSelector, {
			loop: true,
			slidesPerView: 1,
			spaceBetween: 16,
			// autoplay: {
			// 	delay: 2000
			// },
			centeredSlides: false,
			pagination: {
				el: '.swiper-pagination'
			},
			navigation: {
				nextEl: `${elementSelector} + .swiper-buttons .swiper-button-next`,
				prevEl: `${elementSelector} + .swiper-buttons .swiper-button-prev`
			},
			breakpoints: {
				420: {
					slidesPerView: 2
				},
				1025: {
					slidesPerView: 3
				}
			}
		});
	}

	function initFancyBoxAlbum(dataSelector) {
		const options = {
			Thumbs: {
				type: 'classic'
			}
		};

		Fancybox.bind(`[data-fancybox="${dataSelector}"]`, options);
	}

	// API FUNCTIONS FOR ACCOUNT PAGES

	async function getAccessToken() {
		const endpoint = appData.apiUrl;
		const clientId = appData.clientId;
		const clientSecret = appData.clientSecret;

		const response = await fetch(endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Basic ' + btoa(clientId + ':' + clientSecret)
			},
			body: 'grant_type=client_credentials'
		});
		const responseData = await response.json();
		if (responseData.access_token) {
			return responseData.access_token;
		} else {
			console.error('Could not get access token');
			return false;
		}
	}

	async function getAccountNumberOfUser() {
		const email = document.getElementById('user_email');

		if (email) {
			var emailValue = email.textContent.trim();
		} else {
			return;
		}

		const accessToken = await getAccessToken();

		const baseUrl = appData.baseUrl;
		const path = 'accounts/search';
		const url = baseUrl + path;

		const data = {
			searchString: emailValue, // Replace with your actual search string
			useCache: true // or false, depending on your use case
		};

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + accessToken
			},
			body: JSON.stringify(data)
		});

		const responseData = await response.json();
		const accountNumber = responseData.accounts[0].accountNumber;

		if (responseData && responseData.accounts && responseData.accounts[0].accountNumber) {
			return accountNumber;
		} else {
			console.error('Error decoding JSON or missing accountNumber in response');
			return false;
		}
	}

	const accountNumber = getAccountNumberOfUser();

	if (accountNumber != null) {
		displayUserDataForEdit();
		getSingleOrder();
		editAccountInformation();
		canselSubscription();
	}

	async function getDataOfUser() {
		const accessToken = await getAccessToken();
		const accountNumber = await getAccountNumberOfUser();
		const baseUrl = appData.baseUrl;
		const path = `accounts/${accountNumber}`;
		const url = baseUrl + path;

		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + accessToken
			}
		});

		const responseData = await response.json();
		if (responseData) {
			return responseData;
		} else {
			console.error('Error decoding JSON or missing accountNumber in response');
			return false;
		}
	}

	// USED ON EDIT_ACCOUNT
	async function displayUserDataForEdit() {
		if (!$('.page-template-account-page').length) {
			return;
		}

		document.getElementById('loadingOverlay').style.display = 'flex';
		const userData = await getDataOfUser();

		if (userData) {
			const account = userData.account;

			// Update the input placeholders with the initial data
			document.getElementById('baddressCountry').placeholder =
				account.billTo.address.addressCountry || '';
			document.getElementById('baddressLocality').placeholder =
				account.billTo.address.addressLocality || '';
			document.getElementById('bpostalCode').placeholder =
				account.billTo.address.postalCode || '';
			document.getElementById('bstreetAddress').placeholder =
				account.billTo.address.streetAddress || '';

			const genderRadioButtons = document.querySelectorAll('input[name="gender"]');
			const selectedGender = account.billTo.gender || '';

			genderRadioButtons.forEach((radioButton) => {
				if (radioButton.value === selectedGender) {
					radioButton.checked = true;
				}
			});

			document.getElementById('bgivenName').placeholder = account.billTo.givenName || '';
			document.getElementById('bfamilyName').placeholder = account.billTo.familyName || '';
			document.getElementById('bemail').placeholder = account.email || '';
			document.getElementById('bhomePhone').placeholder = account.billTo.homePhone || '';
			document.getElementById('bbirthDate').placeholder = account.billTo.birthDate || '';

			// Update the input placeholders with the initial data
			document.getElementById('saddressCountry').placeholder =
				account.soldTo.address.addressCountry || '';
			document.getElementById('saddressLocality').placeholder =
				account.soldTo.address.addressLocality || '';
			document.getElementById('spostalCode').placeholder =
				account.soldTo.address.postalCode || '';
			document.getElementById('sstreetAddress').placeholder =
				account.soldTo.address.streetAddress || '';

			document.getElementById('sgivenName').placeholder = account.soldTo.givenName || '';
			document.getElementById('sfamilyName').placeholder = account.soldTo.familyName || '';
			document.getElementById('semail').placeholder = account.email || '';
			document.getElementById('shomePhone').placeholder = account.soldTo.homePhone || '';
			document.getElementById('sbirthDate').placeholder = account.soldTo.birthDate || '';

			document.getElementById('loadingOverlay').style.display = 'none';
			document.getElementById('edit').style.display = 'block';
		} else {
			console.error('Failed to retrieve user data');
		}
	}

	function changeTab(tabId) {
		$('.vertical-menu li a').removeClass('btn--selected');
		$(this).addClass('btn--selected');
		$('.tabs').find(`[data-tab='${tabId}']`).show().siblings().hide();

		// Remove the 'btn--selected' class from all tab links
		var tabLinks = document.querySelectorAll('.vertical-menu a');
		tabLinks.forEach(function (link) {
			link.classList.remove('btn--selected');
		});

		// Add the 'btn--selected' class to the clicked tab link
		var selectedTabLink = document.querySelector(`.vertical-menu a[href="${tabId}"]`);
		if (selectedTabLink) {
			selectedTabLink.classList.add('btn--selected');
		}
	}

	function addTabClickEventListeners(selector) {
		document.querySelectorAll(selector).forEach(function (link) {
			link.addEventListener('click', function (event) {
				event.preventDefault();
				var tabId = link.getAttribute('href');
				changeTab(tabId);
			});
		});
	}

	addTabClickEventListeners('.vertical-menu a');
	addTabClickEventListeners('.tabs a');

	function openTab(evt, tabId) {
		$('.tab-content').hide();
		$('.tabE').removeClass('active');
		$('#' + tabId).show();
		$(evt.currentTarget).addClass('active');
	}

	$('.tabE').click(function (event) {
		event.preventDefault();
		openTab(event, $(this).attr('data-tab'));
	});

	function getSingleOrder() {
		if (!$('.order-link').length) {
			return;
		}
		const links = document.querySelectorAll('.order-link');
		if (links.length > 0) {
			links.forEach((link) => {
				link.addEventListener('click', function (event) {
					event.preventDefault();
					// console.log(document.getElementById('loadingOverlay'))
					addOverlay('#loadingOverlay');
					// document.getElementById('loadingOverlay').style.display = 'flex';
					const id = link.getAttribute('data-order_id');

					$.ajax({
						url: appData.ajaxUrl + '?action=app_get_single_order&order_id=' + id
					}).then((response) => {
						var jsonResponse = JSON.parse(response);

						// Access data from the response
						var orderId = jsonResponse.order_id;
						var date = jsonResponse.date;
						var table_content = jsonResponse.table_content;
						if (orderId != null) {
							document.getElementById('orderId').innerHTML = 'Order № ' + orderId;
						}
						if (date != null) {
							document.getElementById('order_date').innerHTML = 'Date: ' + date;
						}
						if (table_content != null) {
							document.getElementById('orderTableBody').innerHTML = table_content;
						}

						removeOverlay('#loadingOverlay');
					});
				});
			});
		}
	}

	function editAccountInformation() {
		$('#accountInformationBillForm').submit(function (event) {
			// Prevent the default form submission
			event.preventDefault();
			document.getElementById('loadingOverlay').style.display = 'flex';
			// Collect form data
			var formData = {
				addressCountry: $('#baddressCountry').val(),
				addressLocality: $('#baddressLocality').val(),
				postalCode: $('#bpostalCode').val(),
				streetAddress: $('#bstreetAddress').val(),
				gender: $('input[name="gender"]:checked').val(),
				givenName: $('#bgivenName').val(),
				familyName: $('#bfamilyName').val(),
				email: $('#bemail').val(),
				homePhone: $('#bhomePhone').val(),
				mobilePhone: $('#bmobilePhone').val(),
				birthDate: $('#bbirthDate').val(),
				update: $('#updateBill').val()
			};
			var queryString = $.param(formData);

			$.ajax({
				url: appData.ajaxUrl + '?action=app_edit_account_information',
				method: 'POST',
				data: formData
			}).then((response) => {
				document.getElementById('loadingOverlay').style.display = 'none';
				alert('The users data updated succesfuly');
				console.log(response);
				location.reload();
			});
		});
		$('#accountInformationSoldForm').submit(function (event) {
			// Prevent the default form submission
			event.preventDefault();
			document.getElementById('loadingOverlay').style.display = 'flex';

			// Collect form data
			var formData = {
				addressCountry: $('#baddressCountry').val(),
				addressLocality: $('#baddressLocality').val(),
				postalCode: $('#bpostalCode').val(),
				streetAddress: $('#bstreetAddress').val(),
				gender: $('input[name="gender"]:checked').val(),
				givenName: $('#bgivenName').val(),
				familyName: $('#bfamilyName').val(),
				email: $('#bemail').val(),
				homePhone: $('#bhomePhone').val(),
				mobilePhone: $('#bmobilePhone').val(),
				birthDate: $('#bbirthDate').val(),
				update: $('#updateBill').val()
			};
			var queryString = $.param(formData);

			$.ajax({
				url: appData.ajaxUrl + '?action=app_edit_account_information',
				method: 'POST',
				data: formData
			}).then((response) => {
				document.getElementById('loadingOverlay').style.display = 'none';
				console.log(response);
			});
		});
		$('#accountInformationBillFormCustomer').submit(function (event) {
			// Prevent the default form submission
			event.preventDefault();
			document.getElementById('loadingOverlay').style.display = 'flex';
			// Collect form data
			var formData = {
				addressCountry: $('#baddressCountry').val(),
				addressLocality: $('#baddressLocality').val(),
				postalCode: $('#bpostalCode').val(),
				streetAddress: $('#bstreetAddress').val(),
				gender: $('input[name="gender"]:checked').val(),
				givenName: $('#bgivenName').val(),
				familyName: $('#bfamilyName').val(),
				email: $('#bemail').val(),
				homePhone: $('#bhomePhone').val(),
				mobilePhone: $('#bmobilePhone').val(),
				birthDate: $('#bbirthDate').val(),
				update: $('#updateBill').val()
			};
			var queryString = $.param(formData);

			$.ajax({
				url: appData.ajaxUrl + '?action=app_edit_account_information',
				method: 'POST',
				data: formData
			}).then((response) => {
				document.getElementById('loadingOverlay').style.display = 'none';
				alert('The users data updated succesfuly');
				console.log(response);
				location.reload();
			});
		});
		$('#accountInformationSoldFormCustomer').submit(function (event) {
			// Prevent the default form submission
			event.preventDefault();
			document.getElementById('loadingOverlay').style.display = 'flex';

			// Collect form data
			var formData = {
				addressCountry: $('#baddressCountry').val(),
				addressLocality: $('#baddressLocality').val(),
				postalCode: $('#bpostalCode').val(),
				streetAddress: $('#bstreetAddress').val(),
				gender: $('input[name="gender"]:checked').val(),
				givenName: $('#bgivenName').val(),
				familyName: $('#bfamilyName').val(),
				email: $('#bemail').val(),
				homePhone: $('#bhomePhone').val(),
				mobilePhone: $('#bmobilePhone').val(),
				birthDate: $('#bbirthDate').val(),
				update: $('#updateBill').val()
			};
			var queryString = $.param(formData);

			$.ajax({
				url: appData.ajaxUrl + '?action=app_edit_account_information',
				method: 'POST',
				data: formData
			}).then((response) => {
				document.getElementById('loadingOverlay').style.display = 'none';
				console.log(response);
			});
		});
	}

	function canselSubscription() {
		if (!$('.page-template-account-page').length) {
			return;
		}
		if (!$('#cancel_subscription')) {
			return;
		}

		$(document).on('click', '#cancel_subscription', function (event) {
			event.preventDefault();
			document.getElementById('loadingOverlay').style.display = 'flex';
			const name = document.getElementById('order_name').textContent.trim();

			$.ajax({
				url: appData.ajaxUrl + '?action=app_cancel_subscription&order_name=' + name
			}).then((response) => {
				console.log(response);
				document.getElementById('loadingOverlay').style.display = 'none';
			});
		});
	}

	// FUNCTIONS FOR ADMIN TO SEARCH FOR OTHER CUSTOMERS WITH PROPULSE API

	function setupAccountActions() {
		if (!document.querySelector('.account-actions')) {
			return;
		}

		$('.account-actions a.link').click(function () {
			var contentId = $(this).data('content');

			// Hide all account content divs
			$('.account-content').hide();

			// Show the selected account content div
			$('#' + contentId).show();

			// Update active class
			$('.account-actions a.link').removeClass('active');
			$(this).addClass('active');
		});
	}

	async function getCustomerAccountNumber(email) {
		var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			console.error('Error: Invalid email format');
			return;
		}

		const accessToken = await getAccessToken();
		const baseUrl = appData.baseUrl;
		const path = `accounts/search`;
		const url = baseUrl + path;
		const data = {
			searchString: email, // Replace with your actual search string
			useCache: true // or false, depending on your use case
		};

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + accessToken
			},
			body: JSON.stringify(data)
		});
		const responseData = await response.json();
		const accountNumber = responseData.accounts[0].accountNumber;

		if (responseData && responseData.accounts && responseData.accounts[0].accountNumber) {
			return accountNumber;
		} else {
			console.error('Error decoding JSON or missing accountNumber in response');
			return false;
		}
	}

	async function getDataOfCustomer(accountNumber) {
		const accessToken = await getAccessToken();
		const baseUrl = appData.baseUrl;
		const path = `accounts/${accountNumber}`;
		const url = baseUrl + path;

		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + accessToken
				}
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const responseData = await response.json();

			if (responseData) {
				return responseData.account;
			} else {
				throw new Error('Error decoding JSON or missing accountNumber in response');
			}
		} catch (error) {
			console.error('Error:', error);
			throw error; // Rethrow the error to handle it elsewhere if needed
		}
	}

	function searchCustomer() {
		// Define references to input elements and other necessary elements
		const emailInput = document.getElementById('searchCustomerByEmail');
		const accountNumberInput = document.getElementById('searchCustomerByAccountNumber');
		const dataOfCustomer = document.getElementById('customerAccountNumber');

		// Variables for "Bill to" and "Sold to" sections
		const billToCountryCustomer = document.getElementById('billToCountryCustomer');
		const billToCityCustomer = document.getElementById('billToCityCustomer');
		const billToPostalCodeCustomer = document.getElementById('billToPostalCodeCustomer');
		const billToStreetCustomer = document.getElementById('billToStreetCustomer');
		const billToNameCustomer = document.getElementById('billToNameCustomer');
		const billToSurnameCustomer = document.getElementById('billToSurnameCustomer');
		const billToEmailCustomer = document.getElementById('billToEmailCustomer');
		const billToPhoneCustomer = document.getElementById('billToPhoneCustomer');
		const billToMobilePhoneCustomer = document.getElementById('billToMobilePhoneCustomer');
		const soldToCountryCustomer = document.getElementById('soldToCountryCustomer');
		const soldToCityCustomer = document.getElementById('soldToCityCustomer');
		const soldToPostalCodeCustomer = document.getElementById('soldToPostalCodeCustomer');
		const soldToStreetCustomer = document.getElementById('soldToStreetCustomer');
		const soldToNameCustomer = document.getElementById('soldToNameCustomer');
		const soldToSurnameCustomer = document.getElementById('soldToSurnameCustomer');
		const soldToEmailCustomer = document.getElementById('soldToEmailCustomer');
		const soldToPhoneCustomer = document.getElementById('soldToPhoneCustomer');
		const soldToMobilePhoneCustomer = document.getElementById('soldToMobilePhoneCustomer');
		const errorHandleDiv = document.querySelector('.error-handle');
		const accountInfoCustomer = document.getElementById('account-information-customer');
		const accountActions = document.querySelector('.account-actions');

		// Determine which input has been filled and get the value
		let searchValue;
		let searchType;
		if (emailInput && emailInput.value.trim() !== '') {
			searchValue = emailInput.value.trim();
			searchType = 'email';
			errorHandleDiv.style.display = 'none';
		} else if (accountNumberInput && accountNumberInput.value.trim() !== '') {
			searchValue = accountNumberInput.value.trim();
			searchType = 'accountNumber';
			errorHandleDiv.style.display = 'none';
		} else {
			errorHandleDiv.style.display = 'block';
			errorHandleDiv.textContent = 'Please enter an email or account number';
			return;
		}

		// Add loading overlay
		addOverlay('#loadingOverlay');

		// Validate email if searching by email
		if (searchType === 'email') {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(searchValue)) {
				accountInfoCustomer.style.display = 'none';
				accountActions.style.display = 'none';
				errorHandleDiv.style.display = 'block';
				errorHandleDiv.textContent = 'Please enter a valid email';
				removeOverlay('#loadingOverlay');
				return;
			}
		}

		// Perform search based on searchType
		if (searchType === 'email') {
			searchByEmail(searchValue);
		} else if (searchType === 'accountNumber') {
			searchByAccountNumber(searchValue);
		}

		async function searchByEmail(email) {
			try {
				const accountNumber = await getCustomerAccountNumber(email);
				if (!accountNumber) {
					dataOfCustomer.textContent = 'Error: Account number not found';
					removeOverlay('#loadingOverlay');
					return;
				}
				dataOfCustomer.textContent = `${accountNumber}`;
				await performSearch(accountNumber);
			} catch (error) {
				handleSearchError(error);
			}
		}

		async function searchByAccountNumber(accountNumber) {
			try {
				await performSearch(accountNumber);
			} catch (error) {
				handleSearchError(error);
			}
		}

		async function performSearch(accountNumber) {
			try {
				const customerData = await getDataOfCustomer(accountNumber);
				if (customerData) {
					// Display customer data for "Bill to" and "Sold to" sections
					billToCountryCustomer.textContent = customerData.billTo.address.addressCountry;
					billToCityCustomer.textContent = customerData.billTo.address.addressLocality;
					billToPostalCodeCustomer.textContent = customerData.billTo.address.postalCode;
					billToStreetCustomer.textContent = customerData.billTo.address.streetAddress;
					billToNameCustomer.textContent = customerData.billTo.givenName;
					billToSurnameCustomer.textContent = customerData.billTo.familyName;
					billToEmailCustomer.textContent = customerData.billTo.email;
					billToPhoneCustomer.textContent = customerData.billTo.homePhone;
					billToMobilePhoneCustomer.textContent = customerData.billTo.mobilePhone;

					// Sold to data
					soldToCountryCustomer.textContent = customerData.soldTo.address.addressCountry;
					soldToCityCustomer.textContent = customerData.soldTo.address.addressLocality;
					soldToPostalCodeCustomer.textContent = customerData.soldTo.address.postalCode;
					soldToStreetCustomer.textContent = customerData.soldTo.address.streetAddress;
					soldToNameCustomer.textContent = customerData.soldTo.givenName;
					soldToSurnameCustomer.textContent = customerData.soldTo.familyName;
					soldToPhoneCustomer.textContent = customerData.soldTo.homePhone;

					// Set up customer data and account actions
					accountInfoCustomer.style.display = 'block';
					accountActions.style.display = 'flex';
					displayCustomerDataForEdit(customerData);
					setupAccountActions();

					// Fetch subscriptions and remove loading overlay
					const accessToken = await getAccessToken();
					getDataOfCustomerSubscriptions(accountNumber, accessToken);
					removeOverlay('#loadingOverlay');
				} else {
					console.log('Error: No data');
					errorHandleDiv.style.display = 'block';
					errorHandleDiv.textContent = 'No data found';
					removeOverlay('#loadingOverlay');
				}
			} catch (error) {
				handleSearchError(error);
			}
		}

		function handleSearchError(error) {
			console.error('Error:', error);
			accountInfoCustomer.style.display = 'none';
			accountActions.style.display = 'none';
			errorHandleDiv.style.display = 'block';
			errorHandleDiv.textContent = 'This user does not have an account yet.';
			removeOverlay('#loadingOverlay');
		}
	}

	// Attach event listeners to the search inputs and button
	function initializeSearch() {
		const emailInput = document.getElementById('searchCustomerByEmail');
		const accountNumberInput = document.getElementById('searchCustomerByAccountNumber');
		const searchButton = document.getElementById('searchAccount');

		if (emailInput) {
			emailInput.addEventListener('keydown', (event) => {
				if (event.key === 'Enter') {
					event.preventDefault();
					searchCustomer();
				}
			});
		}

		if (accountNumberInput) {
			accountNumberInput.addEventListener('keydown', (event) => {
				if (event.key === 'Enter') {
					event.preventDefault();
					searchCustomer();
				}
			});
		}

		if (searchButton) {
			searchButton.addEventListener('click', (event) => {
				event.preventDefault();
				searchCustomer();
			});
		}
	}

	// Initialize event listeners
	initializeSearch();

	async function displayCustomerDataForEdit(account) {
		if (account) {
			// Update the input placeholders with the initial data
			document.getElementById('bCustomerAddressCountry').placeholder =
				account.billTo.address.addressCountry || '';
			document.getElementById('bCustomerAddressLocality').placeholder =
				account.billTo.address.addressLocality || '';
			document.getElementById('bCustomerPostalCode').placeholder =
				account.billTo.address.postalCode || '';
			document.getElementById('bCustomerStreetAddress').placeholder =
				account.billTo.address.streetAddress || '';

			const genderRadioButtons = document.querySelectorAll('input[name="gender"]');
			const selectedGender = account.billTo.gender || '';

			genderRadioButtons.forEach((radioButton) => {
				if (radioButton.value === selectedGender) {
					radioButton.checked = true;
				}
			});

			document.getElementById('bCustomerGivenName').placeholder =
				account.billTo.givenName || '';
			document.getElementById('bCustomerFamilyName').placeholder =
				account.billTo.familyName || '';
			document.getElementById('bCustomerEmail').placeholder = account.email || '';
			document.getElementById('bCustomerHomePhone').placeholder =
				account.billTo.homePhone || '';
			document.getElementById('bCustomerMobilePhone').placeholder =
				account.billTo.mobilePhone || '';
			document.getElementById('bCustomerBirthDate').placeholder =
				account.billTo.birthDate || '';

			// Update the input placeholders with the initial data
			document.getElementById('sCustomerAddressCountry').placeholder =
				account.soldTo.address.addressCountry || '';
			document.getElementById('sCustomerAddressLocality').placeholder =
				account.soldTo.address.addressLocality || '';
			document.getElementById('sCustomerPostalCode').placeholder =
				account.soldTo.address.postalCode || '';
			document.getElementById('sCustomerStreetAddress').placeholder =
				account.soldTo.address.streetAddress || '';

			document.getElementById('sCustomerGivenName').placeholder =
				account.soldTo.givenName || '';
			document.getElementById('sCustomerFamilyName').placeholder =
				account.soldTo.familyName || '';
			document.getElementById('sCustomerEmail').placeholder = account.email || '';
			document.getElementById('sCustomerHomePhone').placeholder =
				account.soldTo.homePhone || '';
			document.getElementById('sCustomerBirthDate').placeholder =
				account.soldTo.birthDate || '';
		} else {
			console.error('Failed to retrieve user data');
		}
	}

	function getDataOfCustomerSubscriptions(accountNumber, accessToken) {
		const baseUrl = appData.baseUrl;
		const path = `accounts/${accountNumber}/subscriptions`;
		const url = baseUrl + path;
		return $.ajax({
			url: url,
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
			dataType: 'json' // Specifies that we expect a JSON response
		})
			.then((responseData) => {
				// Return subscriptions from the response
				// return responseData.subscriptions || [];
				const subscriptions = responseData.subscriptions;
				populateSubscriptionsTable(subscriptions);
			})
			.catch((error) => {
				console.error(`Error fetching subscriptions: ${error.message}`);
				return false;
			});
	}

	function populateSubscriptionsTable(subscriptions) {
		if (!$('#ordersTableBody').length) {
			return;
		}
		const $tableBody = $('#ordersTableBody');
		$tableBody.empty(); // Clear existing content in the table body

		// Iterate over the subscriptions data and create rows for each subscription
		subscriptions.forEach((subscription) => {
			// Create a row element
			const $row = $('<tr>');

			// Create cells for each data point
			const $orderNumberCell = $('<td>').text(subscription.orderId);
			const $dateCell = $('<td>').text(subscription.startDate);
			const $sendToCell = $('<td>').text(
				`${subscription.soldTo.givenName} ${subscription.soldTo.familyName}`
			);
			const $orderTotalCell = $('<td>').text(subscription.offer.name);

			// Create the "View more" link in a cell
			const $actionCell = $('<td>');
			const $viewMoreLink = $('<a>')
				.text('View more')
				.addClass('order-link btn--simple')
				.attr('data-order_id', subscription.orderId);

			$viewMoreLink.on('click', function () {
				// Hide the div with ID orders-information
				$('#orders-information').hide();

				// Get the order ID from the data attribute
				const orderId = $(this).data('order_id');
				// Create a div for order information
				const $orderDetailsDiv = $('<div>').addClass('order-details');

				// Populate the div with order information
				$orderDetailsDiv.html(`
						<h2>Order Details</h2>
						<p><strong>Order ID:</strong> ${subscription.orderId}</p>
						<p><strong>Account Number:</strong> ${subscription.accountNumber}</p>
						<p><strong>Contract Number:</strong> ${subscription.contractNumber}</p>
						<p><strong>Name:</strong> ${subscription.name}</p>
						<p><strong>Offer Name:</strong> ${subscription.offer.name}</p>
						<p><strong>Start Date:</strong> ${subscription.startDate}</p>
						<p><strong>Billing Period:</strong> ${subscription.currentBillingPeriod.start} to ${subscription.currentBillingPeriod.end}</p>
						<p><strong>Contract Period:</strong> ${subscription.currentContractPeriod.start} to ${subscription.currentContractPeriod.end}</p>
						<p><strong>Fullfillment Period:</strong> ${subscription.currentFullfillmentPeriod.start} to ${subscription.currentFullfillmentPeriod.end}</p>
						<p><strong>Sold To:</strong> ${subscription.soldTo.givenName} ${subscription.soldTo.familyName}</p>
						<p><strong>Bill To:</strong> ${subscription.billTo.givenName} ${subscription.billTo.familyName}</p>
						<p><strong>Payment Method:</strong> ${subscription.paymentMethod.type}</p>
						<p><strong>Status:</strong> ${subscription.currentBillingPeriod.status}</p>
						<p><strong>Contracted MRR:</strong> ${subscription.contractedMrr}</p>
					`);

				// Add a "Back" button to the order details div
				const $backButton = $('<button>').text('Back').addClass('btn btn--back');

				// Attach a click event handler to the "Back" button
				$backButton.on('click', function () {
					// Hide the div with class order-information
					$('.order-information').hide();
					// Show the div with ID orders-information
					$('#orders-information').show();
				});

				// Append the "Back" button to the order details div
				$orderDetailsDiv.append($backButton);

				// Append the order details div to the order-information div
				$('.order-information').empty().append($orderDetailsDiv);

				// Show the div with class order-information
				$('.order-information').show();
			});
			// Append the link to the cell
			$actionCell.append($viewMoreLink);

			// Append all cells to the row
			$row.append($orderNumberCell);
			$row.append($dateCell);
			$row.append($sendToCell);
			$row.append($orderTotalCell);
			$row.append($actionCell);

			// Append the row to the table body
			$tableBody.append($row);
		});
	}

	function makeFormPopup() {
		var myForm = document.getElementById('formID');
		myForm.onsubmit = function () {
			var w = window.open(
				'about:blank',
				'Popup_Window',
				'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=400,height=300,left = 312,top = 234'
			);
			this.target = 'Popup_Window';
		};
	}

	function monitorIframe() {
		let iframeEl = document.getElementById('payment-iframe');

		if (iframeEl === null) {
			return;
		}

		window.setInterval(iframeChecker, 1000);

		function iframeChecker() {
			try {
				var iframeElUrl = iframeEl.contentWindow.location.href;
			} catch {
				return;
			}

			if (iframeElUrl.includes('biovancia')) {
				if (redirectInProgress === false) {
					redirectInProgress = true;
					window.location.href = iframeElUrl;
				}
			}
		}
	}
	monitorIframe();

	// START FUNCTIONS FOR CATEGORISED PRODUCTS, SCROLLING TO THEM AND LINKS REDIRECT

	function scrollProductCategories() {
		$('.menu-item-object-product_cat').on('click', function (event) {
			// If the clicked element is an <a> tag, prevent the default action
			event.preventDefault();
			// If the clicked element is not an <a> tag, find the <a> tag within the clicked <li> element
			var link = $(this).find('a');
			var subMenuParent = $(this).closest('.sub-menu');
			var mainPageLink = subMenuParent.siblings('a').attr('href');
			var currentPageLink = window.location.href;

			// Remove hash fragments from URLs for comparison
			var baseMainPageLink = removeHash(mainPageLink);
			var baseCurrentPageLink = removeHash(currentPageLink);

			if (baseCurrentPageLink == baseMainPageLink) {
				console.log('Pages are the same');
				// We're on the correct page, scroll to the heading
				scrollToHeading(event);
			} else {
				console.log('Pages are different');
				// Redirect to the correct page, passing the target heading as a hash
				var newUrl = mainPageLink + '#' + encodeURIComponent(link.text().trim());
				window.location.href = newUrl; // This will trigger the page navigation
			}
		});

		// Check if we need to scroll after navigating to a new page
		$(window).on('load', function () {
			if (window.location.hash) {
				var hash = window.location.hash.substring(1); // Get the hash value without '#'
				scrollToHeadingByText(decodeURIComponent(hash)); // Decode and scroll to the target
			}
		});
	}
	scrollProductCategories();

	function removeHash(url) {
		var urlParts = url.split('#');
		return urlParts[0]; // Return the part before the '#'
	}
	// Function to scroll to the heading with the specified text
	function scrollToHeading(event) {
		// Get the text content of the clicked <a> tag
		var linkText = $(event.target).text().trim();

		// Find the <h2> element with the same text content
		var targetHeading = $('h2.section-title').filter(function () {
			return $(this).text().trim() === linkText;
		});
		console.log(targetHeading);
		// Scroll to the <h2> element if found
		if (targetHeading.length) {
			$('html, body').animate(
				{
					scrollTop: targetHeading.offset().top - 150 // Adjust the offset as needed
				},
				1000
			); // Adjust the duration as needed
		}
	}

	// Function to scroll to the heading by text
	function scrollToHeadingByText(linkText) {
		// Find the <h2> element with the same text content
		var targetHeading = $('h2.section-title').filter(function () {
			return $(this).text().trim() === linkText;
		});

		// Scroll to the <h2> element if found
		if (targetHeading.length) {
			$('html, body').animate(
				{
					scrollTop: targetHeading.offset().top - 150 // Adjust the offset as needed
				},
				1000
			); // Adjust the duration as needed
		}
	}

	// END FUNCTIONS FOR CATEGORISED PRODUCTS, SCROLLING TO THEM AND LINKS REDIRECT

	function productLinkParentRedirect() {
		$('.menu-item-object-product').on('click', function (event) {
			var link = $(this).find('a');
			var href = link.attr('href');
			if (href) {
				event.preventDefault();
				window.location.href = href;
			}
		});
	}
	productLinkParentRedirect();

	function initCartPrefill() {
		if (!$('#cart-prefill').length) {
			return;
		}

		$('#cart-prefill').on('submit', function (e) {
			e.preventDefault();

			const formData = $(this).serializeArray();
			const couponValue = formData[0]['value'];

			addOverlay('.app-loader');

			$.ajax({
				url: appData.ajaxUrl,
				data: {
					action: 'app_add_product_to_cart',
					coupon: couponValue
				},
				success: function (response) {
					if (response.success === true) {
						window.location.href = response.data;
					} else {
						console.log(response);
						removeOverlay('.app-loader');
					}
				},
				error: function (e) {
					console.log('Product Error' + e);
					removeOverlay('.app-loader');
				}
			});
		});
	}

	function initSnackbar() {
		if (!$('.snackbar').length) {
			return;
		}

		$('.snackbar').on('click', function (e) {
			e.preventDefault();

			$(this).animate({ top: '110%' }, 600, function () {
				$(this).fadeOut('slow');
			});
		});
	}

	function initCustomCouponsForm() {
		if (!$('.form-custom-coupon').length) {
			return;
		}

		$('.form-custom-coupon').on('submit', function (e) {
			e.preventDefault();

			const formData = $(this).serializeArray();
			const couponValue = formData[0]['value'];

			addOverlay('.app-loader');

			$.ajax({
				url: appData.ajaxUrl,
				data: {
					action: 'app_custom_coupon_form',
					coupon: couponValue,
					page: appData.pageId
				},
				success: function (response) {
					let messageData = response.data;

					if (response.success === true) {
						window.location.href = messageData;
					} else {
						if (messageData.length) {
							$('.form__message').html(`<p>${messageData}</p>`);
						}
						removeOverlay('.app-loader');
					}
				},
				error: function (e) {
					console.log('Error');
					removeOverlay('.app-loader');
				}
			});
		});
	}

	function wcmFieldCorrection() {
		function fixPostcodePosition() {
			var postcodeField = $('#billing_postcode_field'); // Adjust for shipping if needed
			var targetField = $('#billing_address_1_field'); // Position after this field
	
			if (postcodeField.length && targetField.length) {
				postcodeField.insertAfter(targetField);
			}
		}
	
		// Run on page load
		fixPostcodePosition();
	
		// Reapply when the checkout form is updated (e.g., country change)
		$(document.body).on('updated_checkout', function() {
			fixPostcodePosition();
		});
	
		// Run after country changes
		$(document.body).on('country_to_state_changed', function() {
			fixPostcodePosition();
		});
	}
})(jQuery);
